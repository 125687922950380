<template>
  <div class="row justify-content-center">
    <div class="col-12">
      <div class="card card-custom">
        <div class="card-body p-0">
          <!--begin: Wizard-->
          <div
            class="wizard wizard-1"
            id="kt_wizard_v1"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
          >
            <!--begin: Wizard Body-->
            <div
              class="row justify-content-center my-10 px-8 my-lg-10 px-lg-8"
            >
              <div class="col-xl-12">
                <!--begin: Wizard Form-->
                <Form
                  :form="form"
                  :title="'Catatan Penimbangan'"
                  route="api/weighing-notes"
                />
                <!--end: Wizard Form-->
              </div>
            </div>
            <!--end: Wizard Body-->
          </div>
        </div>
        <!--end: Wizard-->
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Form from "@/view/components/weighing-notes/Form.vue";
import { getUser, setUser } from "@/core/services/jwt.service.js";

export default {
  components: {
    Form,
  },
  data() {
    return {
      form: {
        posyandu_id: getUser().posyandu_id,
        toddler_id: '',
        registration_number: "",
        weight: "",
        height: "",
        head_circumference: "",
        weighing_date: new Date().toISOString().substr(0, 10),
        is_vitamin: 0,
        vitamin_list: "",
        is_immunization: 0,
        immunization_list: "",
        staff_id: ''
      },
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Penimbangan Bayi & Balita", route: "/weighing-notes" },
      { title: "Tambah" },
    ]);
  },
};
</script>